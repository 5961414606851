/* eslint-disable react-native/no-inline-styles */
import DetailNavTitle from '@/components/business/detail-nav-title';
import i18n from '@/i18n';
import theme from '@/style';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {View, RefreshControl} from 'react-native';
import Table, {
  TextStyle,
  backgroundColor,
  tableLeft,
  teamReportDataObj,
} from './components/table';
import ProxySearch from './components/proxy-search';
import {goBack, goTo} from '@/utils';
import LinearGradient from '@basicComponents/linear-gradient';
import Text from '@basicComponents/text';
import {getTeamReportDataList} from './proxy.service';
import ErrorInvitePage from './basic-components/error-user-page';
import {useShare} from '../hooks/share.hooks';

import {
  background,
  fill,
  flex,
  fontSize,
  padding,
  position,
} from '@/components/style';
import {ScrollView} from 'react-native-gesture-handler';
import {useInnerStyle} from './proxy.hooks';
import Sort from './components/sort';
import {SafeAny} from '@/types';
import {useFocusEffect} from '@react-navigation/native';
import Tabs from './components/tabs';
import {DateInlineFilter} from '@/components/business/filter';
import ProxyTeamReportResult from './components/proxy-team-report-result';
import dayjs from 'dayjs';

const ProxyTeamReport = () => {
  const {doShare} = useShare(true);
  const userSortRef: any = useRef(null);
  const rechargeSortRef: any = useRef(null);
  const bettingSortRef: any = useRef(null);
  const commissionSortRef: any = useRef(null);
  const scrollViewRef: any = useRef(null);
  const [phone, setPhone] = useState('');
  const {size} = useInnerStyle();
  const [refreshing, setRefreshing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [teamReportData, setTeamReportData] = useState<teamReportDataObj[]>([]);
  const [userWidth, setUserWidth] = useState(63);
  const [rechargeWidth, setRechargeWidth] = useState(78);
  const [bettingWidth, setBettingWidth] = useState(78);
  const [commissionWidth, setCommissionWidth] = useState(93);
  const [dateRange, setDateRange] = useState<string[] | null>();
  const [totalSize, setTotalSize] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const [next, setNext] = useState(false);
  const handleRefresh = async () => {
    setRefreshing(true);
  };
  const handleTabIndex = (str: string) => {
    setTeamReportData([]);
    setNext(false);
    setParameter({
      ...parameter,
      pageNo: 1,
      leve: Number(str),
    });
  };
  const [parameter, setParameter] = useState<SafeAny>({
    leve: 1,
    pageNo: 1,
    pageSize: 15,
    startDate: null,
    endDate: null,
    userSort: 0,
    rechargeSort: 0,
    bettingSort: 0,
    commissionSort: 0,
    searchWord: '',
  });
  useEffect(() => {
    handleTeamReportDataList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleTeamReportDataList = () => {
    setLoading(true);
    getTeamReportDataList(parameter)
      .then((res: SafeAny) => {
        setLoading(false);
        setTotalSize(res.totalSize);
        let arr: teamReportDataObj[] = [];
        let data: SafeAny = arr.concat(teamReportData);

        if (refreshing || next) {
          setTeamReportData(res.content);
        } else {
          setTeamReportData(data.concat(res.content));
        }
        setRefreshing(false);
      })
      .catch(() => {
        if (parameter.pageNo > 1) {
          setParameter({
            ...parameter,
            pageNo: parameter.pageNo - 1,
          });
        }
        setLoading(false);
        setRefreshing(false);
      });
  };
  const handleNext = () => {
    setParameter({
      ...parameter,
      pageNo: parameter.pageNo + 1,
    });
    setNext(false);
  };
  useFocusEffect(
    useCallback(() => {
      if (refreshing) {
        handleTeamReportDataList();
        return;
      } else {
        handleTeamReportDataList();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parameter, refreshing]),
  );
  useEffect(() => {
    if (!phone) {
      parameter.pageNo = 1;
      setTeamReportData([]);
    } else {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phone]);
  useEffect(() => {
    if (dateRange) {
      setTeamReportData([]);
      setParameter({
        ...parameter,
        startDate: dateRange[0],
        endDate: dateRange[1],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);
  useEffect(() => {
    if (isFocused) {
      goTo('ProxyTeamReportSearch');
    }
  }, [isFocused]);
  return (
    <View
      style={[
        {height: size.screenHeight},
        flex.col,
        {
          backgroundColor: 'var(--BG)',
        },
      ]}>
      <DetailNavTitle
        onBack={goBack}
        hideServer
        hideAmount
        title={i18n.t('proxy.team.title')}
      />
      <ScrollView
        stickyHeaderIndices={[2]}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={handleRefresh} />
        }
        style={[theme.flex.col, theme.flex.flex, flex.flex1, {marginTop: -1}]}>
        {!phone && (
          <div
            className="h-16"
            style={{
              background: 'var(--linear-filter)',
            }}>
            <View style={[theme.margin.l]}>
              <View
                style={[flex.flex, flex.row, flex.between, flex.centerByCol]}>
                <View style={[flex.flex1]}>
                  <ProxySearch
                    searchValue={phone}
                    onSearchValueChange={setPhone}
                    placeholder={i18n.t('proxy.team.search')}
                    setIsFocused={setIsFocused}
                  />
                </View>
                {/* {phone && (
                  <NativeTouchableOpacity
                    onPress={() => {
                      tableRef?.current.handleTeamReportDataList();
                    }}
                    style={[searchBox, borderRadius.xs, flex.center]}>
                    <Text style={[searchText, {fontWeight: 'bold'}]}>
                      Search
                    </Text>
                  </NativeTouchableOpacity>
                )} */}
              </View>
            </View>
          </div>
        )}

        {!phone && <ProxyTeamReportResult />}
        {!phone && (
          <View style={[{height: 122}]}>
            <View
              style={[
                padding.l,
                flex.flex,
                flex.between,
                flex.row,
                background.white,
                {paddingBottom: 0},
              ]}>
              <View>
                <Text
                  style={[
                    {color: theme.fontColor.main},
                    {fontSize: fontSize.m},
                    {fontWeight: 'bold'},
                  ]}>
                  {i18n.t('proxy.team-report.my-teams')}
                </Text>
              </View>
              <View
                style={[theme.flex.flex, theme.flex.row, theme.flex.between]}>
                <DateInlineFilter
                  requiredInit={false}
                  validRange={{
                    startDate: dayjs().subtract(1, 'month').toDate(),
                    endDate: new Date(),
                  }}
                  dateRange={dateRange}
                  onDateRangeChanged={setDateRange}
                />
              </View>
            </View>
            <View
              style={[
                padding.tbl,
                flex.between,
                flex.row,
                background.white,
                {height: 60},
                fill.fillW,
              ]}>
              <Tabs
                handleTabIndex={handleTabIndex}
                backgroundColor="var(--BG)"
              />
            </View>
            {!!totalSize && (
              <View
                style={[
                  phone ? backgroundColor : theme.background.white,
                  flex.flex,
                  flex.row,
                  flex.alignStart,
                  {height: 28},
                ]}>
                <View style={[flex.row, {height: 28}]}>
                  <View>
                    <View
                      style={[
                        tableLeft,
                        theme.flex.flex,
                        theme.flex.center,
                        {width: 110},
                      ]}>
                      <Text style={[TextStyle]}>
                        {i18n.t('proxy.team-report.user-id')}
                      </Text>
                    </View>
                  </View>
                </View>
                <ScrollView horizontal={true} ref={scrollViewRef}>
                  <View
                    style={[
                      flex.row,
                      flex.centerByCol,
                      {backgroundColor: '#3E434F', height: 28},
                    ]}>
                    <View>
                      <View
                        style={[
                          padding.rights,
                          padding.leftl,
                          {width: userWidth},
                        ]}>
                        <Sort
                          ref={userSortRef}
                          title={i18n.t('proxy.team-report.user')}
                          handleClick={() => {
                            let obj: any = {...parameter};
                            obj.userSort = userSortRef.current.num();
                            rechargeSortRef.current.handelSetNum(0);
                            bettingSortRef.current.handelSetNum(0);
                            commissionSortRef.current.handelSetNum(0);
                            setParameter({
                              ...obj,
                              pageNo: 1,
                              rechargeSort: 0,
                              bettingSort: 0,
                              commissionSort: 0,
                            });
                            setNext(true);
                          }}
                        />
                      </View>
                    </View>
                    <View>
                      <View style={[padding.rights, {width: rechargeWidth}]}>
                        <Sort
                          title={i18n.t('proxy.team-report.recharge')}
                          ref={rechargeSortRef}
                          handleClick={() => {
                            let obj: any = {...parameter};
                            obj.rechargeSort = rechargeSortRef.current.num();
                            userSortRef.current.handelSetNum(0);
                            bettingSortRef.current.handelSetNum(0);
                            commissionSortRef.current.handelSetNum(0);
                            setParameter({
                              ...obj,
                              pageNo: 1,
                              userSort: 0,
                              bettingSort: 0,
                              commissionSort: 0,
                            });
                            setNext(true);
                          }}
                        />
                      </View>
                    </View>
                    <View>
                      <View style={[padding.rights, {width: bettingWidth}]}>
                        <Sort
                          title={i18n.t('proxy.team-report.betting')}
                          ref={bettingSortRef}
                          handleClick={() => {
                            let obj: any = {...parameter};
                            obj.bettingSort = bettingSortRef.current.num();
                            userSortRef.current.handelSetNum(0);
                            rechargeSortRef.current.handelSetNum(0);
                            commissionSortRef.current.handelSetNum(0);
                            setParameter({
                              ...obj,
                              pageNo: 1,
                              userSort: 0,
                              rechargeSort: 0,
                              commissionSort: 0,
                            });
                            setNext(true);
                          }}
                        />
                      </View>
                    </View>
                    <View>
                      <View style={[padding.rights, {width: commissionWidth}]}>
                        <Sort
                          title={i18n.t('proxy.team-report.commission')}
                          ref={commissionSortRef}
                          handleClick={() => {
                            let obj: any = {...parameter};
                            obj.commissionSort =
                              commissionSortRef.current.num();
                            userSortRef.current.handelSetNum(0);
                            rechargeSortRef.current.handelSetNum(0);
                            bettingSortRef.current.handelSetNum(0);
                            setParameter({
                              ...obj,
                              pageNo: 1,
                              userSort: 0,
                              rechargeSort: 0,
                              bettingSort: 0,
                            });
                            setNext(true);
                          }}
                        />
                      </View>
                    </View>
                  </View>
                </ScrollView>
                <View style={[position.abs, {right: 0, height: '100%'}]}>
                  <LinearGradient
                    style={[{width: 32, height: '100%'}, flex.center]}
                    colors={['#262B33 ', 'rgba(38, 43, 51, 0.00)']}
                    start={{x: 1, y: 1}}
                    end={{x: 0, y: 1}}
                  />
                </View>
              </View>
            )}
          </View>
        )}
        {!!totalSize ? (
          <View style={[{marginTop: -22}]}>
            <Table
              scrollViewRef={scrollViewRef}
              refreshing={refreshing}
              setUserWidth={setUserWidth}
              setRechargeWidth={setRechargeWidth}
              setBettingWidth={setBettingWidth}
              setCommissionWidth={setCommissionWidth}
              loading={loading}
              handleNext={handleNext}
              teamReportData={teamReportData}
              parameter={parameter}
              totalSize={totalSize}
            />
          </View>
        ) : (
          <ErrorInvitePage
            content={i18n.t('proxy.new-user.error')}
            buttonTitle={i18n.t('proxy.team.contact')}
            onClick={() => doShare()}
          />
        )}
      </ScrollView>
    </View>
  );
};

export default ProxyTeamReport;
